import { any, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	find,

	/**
	 * Get the total number of pages in the module.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Number}
	 */
	count(state, getters) {
		if (getters.hasFilter || getters.hasProviderFilter) {
			return state.filters.ids.length
		}

		return state.ids.length
	},

	/**
	 * Find a page with the given SID.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @param {Object} rootState
	 * @param {Object} rootGetters
	 * @return {Function}
	 */
	findBySid(state, getters, rootState, rootGetters) {
		return sid => {
			return rootGetters.pages.find(page => page.sid === sid)
		}
	},

	/**
	 * Determine if any filters exist in the store.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	hasFilter(state) {
		if (state.filters.starts) {
			return true
		}

		if (state.filters.ends) {
			return true
		}

		if (state.filters.search.length > 0) {
			return true
		}

		if (state.filters.statuses.length > 0) {
			return true
		}

		if (state.filters.providers.length > 0) {
			return true
		}

		return false
	},

	/**
	 * Determine if the provider filter exist in the store.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	hasProviderFilter(state) {
		return state.filters.providers.length > 0
	},

	/**
	 * Get the meta object in the module.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Object}
	 */
	meta(state, getters) {
		if (!getters.hasFilter && !getters.hasProviderFilter) {
			return state.meta
		}

		return state.filters.meta
	},

	/**
	 * Get the current pages.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Array}
	 */
	pages(state, getters) {
		const ids =
			getters.hasFilter || getters.hasProviderFilter
				? state.filters.ids
				: state.ids
		return ids.map(id => state.items[id]).filter(Boolean)
	},
}

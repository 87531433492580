export default {
	browser: {
		error:
			'You are using an unsupported browser. Certain features may not work properly. Please switch to either Google Chrome, Mozilla Firefox, or Safari.',
	},

	calls: {
		assign: {
			success: 'The call was assigned successfully.',
			error: 'Something went wrong. The call was not assigned.',
		},

		complete: {
			success: 'The call was completed successfully.',
			error: 'Something went wrong. The call was not completed.',
		},

		dismiss: {
			success: 'The call was dismissed successfully.',
			error: 'Something went wrong. The call was not Dismissed.',
		},
	},

	chat: {
		channel: {
			create: {
				success: 'The conversation was created successfully.',
				error:
					'Something went wrong. The conversation was not created.',
			},
		},
		message: {
			text: {
				error: 'Something went wrong. The message could not be sent.',
			},
		},
	},

	copy: {
		error: 'The original event has been deleted. Unable to copy.',
	},

	default: {
		error: 'Something went wrong. Please try again later.',
	},

	event: {
		create: {
			success: 'The event was created successfully.',
			error: 'Something went wrong. The event was not created.',
		},

		delete: {
			success: 'The event was deleted successfully.',
			error: 'Something went wrong. The event was not deleted.',
		},

		update: {
			success: 'The event was updated successfully.',
			error: 'Something went wrong. The event was not updated.',
		},
	},

	logout: 'You were signed out successfully.',

	pages: {
		assign: {
			success: 'The page was reassigned successfully.',
			error: 'Something went wrong. The page was not reassigned.',
		},

		create: {
			success: 'The page was sent successfully.',
			error: 'Something went wrong. The page could not be sent.',
		},

		dismiss: {
			success: 'The page was dismissed successfully.',
			error: 'Something went wrong. The page was not dismissed.',
		},
	},

	notes: {
		create: {
			success: 'The note was added successfully.',
			error: 'Something went wrong. The note was not added to the call.',
		},
		delete: {
			success: 'The note was deleted successfully.',
			error:
				'Something went wrong. The note was not deleted from the call.',
		},
		update: {
			success: 'The note was updated successfully.',
			error: 'Something went wrong. The note was not updated.',
		},
	},

	omd: {
		release:
			'A new version of OMD has been released and a refresh is required.',
	},

	partners: {
		change: {
			success: 'The active partner was changed successfully.',
			error: 'Something went wrong. The active partner was not changed.',
		},

		success: 'The partner was updated successfully.',
		error: 'Something went wrong. The partner was not updated.',
	},

	password: {
		forgot: 'Password reset instructions have been sent to your email.',
		reset: 'Your password has been reset successfully.',
	},

	providers: {
		success: 'The provider was updated successfully.',
		error: 'Something went wrong. The provider was not updated.',
	},

	timeBlocks: {
		create: {
			success: 'The time block was created successfully.',
			error: 'Something went wrong. The time block was not created.',
		},

		delete: {
			success: 'The time block was deleted successfully.',
			error: 'Something went wrong. The time block was not deleted.',
		},

		update: {
			success: 'The time block was updated successfully.',
			error: 'Something went wrong. The time block was not updated.',
		},
	},
}

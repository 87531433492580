import Message from './Message'

/**
 * Alert error message.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class ErrorMessage extends Message {
	/**
	 * Create a new ErrorMessage instance.
	 *
	 * @param {String} message
	 */
	constructor(message) {
		super('error', 'Error:', message)
	}
}

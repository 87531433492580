import StateFactory from '@/store/helpers/StateFactory'

/**
 * The module's default state.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default function state() {
	return StateFactory.paginatedEntity({
		filters: {
			ids: [],
			meta: {},
			providers: [],
			search: '',
			statuses: [],
			starts: null,
			ends: null,
		},
		incomplete: {
			urgent: 0,
			nonUrgent: 0,
			total: 0,
		},
		loading: false,
	})
}

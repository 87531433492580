import moment from 'moment'
import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'
import {
	ADD_NOTE_EVENT,
	UPDATE_CALL,
	ADD_NOTE_TO_CALL,
	ADD_TO_FILTER,
	UPDATE_TO_FILTER,
	ADD_RECENT_CALL,
	COMPLETE_CALL,
	DISMISS_CALL,
	REMOVE_ALL_RECENT_CALLS,
	REMOVE_CALL_TYPE_FILTER,
	REMOVE_DATE_RANGE_FILTER,
	REMOVE_NOTE_FROM_CALL,
	REMOVE_RECENT_CALL,
	REMOVE_SEARCH_FILTER,
	REMOVE_STATUS_FILTERS,
	RESET_FILTERS,
	SET_ENDS_TIME_FILTER,
	SET_STARTS_TIME_FILTER,
	SET_CALL_PROVIDER_FILTERS,
	SET_CALL_TYPE_FILTERS,
	SET_FILTER_IDS,
	SET_FILTER_META,
	SET_INCOMPLETE_CALL_COUNT,
	SET_NON_URGENT_CALL_FILTER,
	SET_STATUS_FILTERS,
	SET_SEARCH_FILTER,
	SET_URGENT_CALL_FILTER,
	UPDATE_CALL_ATTRIBUTE,
	UPDATE_CALL_NOTE,
	SET_ITEMS,
	SET_INCOMPLETE_TOTAL_COUNT_ONLY,
} from '@/store/mutation-types'
import { DEFAULT_VAL } from '@/config/calls'

/**
 * Make a new call note event model.
 *
 * @return {Object}
 */
const makeEvent = (note, summary) => {
	return {
		summary,
		type: 'note',
		agent: `${note.user.first_name} ${note.user.last_name}`,
		call_id: note.call_id,
		created_at: note.created_at,
		id: Math.floor(Math.random() * 100000),
		updated_at: note.updated_at,
	}
}

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Add the recent call id to the store.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Number} id
	 * @return {void}
	 */
	addRecentCall({ commit }, id) {
		commit(ADD_RECENT_CALL, id)
	},

	/**
	 * Add a new call note to a call.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Object} note
	 * @return {void}
	 */
	async addNote({ commit, dispatch, state }, note) {
		if (state.ids.includes(note.call_id)) {
			commit(ADD_NOTE_TO_CALL, note)

			await dispatch('addNoteCreatedEvent', note)
		}
	},

	async addToFilter({ commit }, call) {
		commit(ADD_TO_FILTER, call)
	},

	async updateToFilter({ commit }, call) {
		commit(UPDATE_TO_FILTER, call)
	},

	/**
	 * Add a note created event to a call.
	 *
	 * @param {Function} options.commit
	 * @param {Object} note
	 * @return {void}
	 */
	addNoteCreatedEvent({ commit }, note) {
		const fullName = `${note.user.first_name} ${note.user.last_name}`

		const event = makeEvent(
			note,
			`${fullName} added note: "${note.content}"`
		)

		commit(ADD_NOTE_EVENT, event)
	},

	updateCall({ commit }, call) {
		commit(UPDATE_CALL, call)
	},

	/**
	 * Add a note deleted event to a call.
	 *
	 * @param {Function} options.commit
	 * @param {Object} note
	 * @return {void}
	 */
	addNoteDeletedEvent({ commit }, note) {
		const fullName = `${note.user.first_name} ${note.user.last_name}`
		const format = 'YYYY-MM-DD HH:mm:ss'
		const timestamp = moment
			.utc(note.created_at, format)
			.format('MM/DD/YYYY hh:mm')

		const event = makeEvent(
			note,
			`${fullName} deleted note (created ${timestamp} UTC): "${note.content}"`
		)

		commit(ADD_NOTE_EVENT, event)
	},

	/**
	 * Add a note updated event to a call.
	 *
	 * @param {Function} options.commit
	 * @param {Object} note
	 * @return {void}
	 */
	addNoteUpdatedEvent({ commit }, note) {
		const fullName = `${note.user.first_name} ${note.user.last_name}`
		const format = 'YYYY-MM-DD HH:mm:ss'
		const timestamp = moment
			.utc(note.created_at, format)
			.format('MM/DD/YYYY hh:mm')

		const event = makeEvent(
			note,
			`${fullName} edited note (created ${timestamp} UTC): "${note.content}"`
		)

		commit(ADD_NOTE_EVENT, event)
	},

	/**
	 * Mark the given call sid as complete.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Function } options.rootGetters
	 * @param {String} payload.sid
	 * @param {String} payload.message
	 * @return {Response}
	 */
	async complete(
		{ commit, dispatch, rootGetters },
		{ sid, message, closeDrawer }
	) {
		await api()
			.calls()
			.complete(sid, message || '')

		const response = await api()
			.partners(rootGetters.partner.id)
			.calls()
			.find(sid)

		closeDrawer()

		await dispatch('get')

		await commit(COMPLETE_CALL, response.get('data', {}))

		dispatch('callTypes/get', null, { root: true })

		dispatch('partners/get', null, { root: true })

		return response
	},

	/**
	 * Mark the given call sid as Dismiss.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Function } options.rootGetters
	 * @param {String} payload.sid
	 * @param {String} payload.message
	 * @return {Response}
	 */
	async dismiss({ commit, dispatch, rootGetters }, { sid, message }) {
		await api()
			.calls()
			.dismiss(sid, message || '')

		const response = await api()
			.partners(rootGetters.partner.id)
			.calls()
			.find(sid)

		await commit(DISMISS_CALL, response.get('data', {}))

		dispatch('getIncompleteCallCount')

		dispatch('callTypes/get', null, { root: true })

		dispatch('partners/get', null, { root: true })

		return response
	},

	/**
	 * Delete a call note from a call.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Object} note
	 * @return {void}
	 */
	async deleteNote({ commit, dispatch, state }, note) {
		if (state.ids.includes(note.call_id)) {
			commit(REMOVE_NOTE_FROM_CALL, note)

			await dispatch('addNoteDeletedEvent', note)
		}
	},

	/**
	 * Fetch an specific call.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {String} payload.sid
	 * @return {Response}
	 */
	async find({ dispatch, rootGetters }, sid) {
		const response = await api()
			.partners(rootGetters.partner.id)
			.calls()
			.find(sid)

		dispatch('add', response.get('data'))

		return response
	},

	/**
	 * Fetch the given page of (filtered) call models.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.getters
	 * @param {Object} options.rootGetters
	 * @param {Object} options.state
	 * @param {Number} payload.page
	 * @return {Response}
	 */
	async get({ commit, dispatch, getters, state, rootGetters }, payload = 1) {
		const page = typeof payload !== 'object' ? payload : 1

		try {
			commit('SET_LOADING', true)
			const requests = [
				api()
					.partners(rootGetters.partner.id)
					.calls()
					.page(page)
					.callsOnly()
					.starts(state.filters.starts)
					.ends(state.filters.ends)
					.filters(state.filters.statuses)
					.providers(state.filters.providers)
					.types(state.filters.types.map(type => type.id))
					.urgent(state.filters.urgent || false)
					.nonUrgent(state.filters.nonUrgent || false)
					.search(state.filters.search)
					.get(true),
			]

			if (page === 1) {
				requests.push(dispatch('getIncompleteCallCount'))
			}

			const [response] = await Promise.all(requests)

			if (page === 1) {
				if (getters.hasFilter || getters.hasProviderFilter) {
					commit(RESET_FILTERS)
				} else {
					commit(SET_ITEMS, [])
				}
			}

			commit('SET_LOADING', false)
			return await dispatch('handleResponse', response)
		} catch (error) {
			commit('SET_LOADING', false)
			console.log('Logging error:', error)
		} finally {
			if (payload?.fromFilters && payload?.callId) {
				dispatch('find', payload.callId)
			}
			commit('SET_LOADING', false)
		}
	},

	/**
	 * Get the incomplete call count statistics from the API.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.rootGetters
	 * @return {Response}
	 */
	async getIncompleteCallCount({ commit, dispatch, rootGetters, state }) {
		const response = await api()
			.partners(rootGetters.partner.id)
			.calls()
			.callsOnly()
			.starts(state.filters.starts)
			.ends(state.filters.ends)
			.count(true)
		let setTotal = true
		if (state.filters.starts && state.filters.ends) {
			setTotal = false
		}

		commit(SET_INCOMPLETE_CALL_COUNT, {
			setTotal,
			...response.get('data', {}),
		})
		const callCount = response.get('data', {})
		if (setTotal) {
			dispatch('partners/incrementPendingCallsCountById', callCount, {
				root: true,
			})
		}
		return response
	},

	async setIncompleteCallCount({ commit }, callCount) {
		commit(SET_INCOMPLETE_CALL_COUNT, callCount)
	},

	/**
	 * Handle an API request's response.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.getters
	 * @param {Response} response
	 * @return {Response}
	 */
	async handleResponse({ dispatch, getters }, response) {
		await dispatch('add', response.get('data'))

		if (getters.hasFilter || getters.hasProviderFilter) {
			return dispatch('handleFilteredResponse', response)
		}

		await dispatch('setMeta', response.get('meta'))

		return response
	},

	/**
	 * Handle an API request's response containing filtered data.
	 *
	 * @param {Function} options.commit
	 * @param {Response} response
	 * @return {Response}
	 */
	handleFilteredResponse({ commit }, response) {
		commit(SET_FILTER_IDS, response.get('data'))

		commit(SET_FILTER_META, response.get('meta'))

		return response
	},

	/**
	 * Remove all the recent calls from the store.
	 *
	 * @param {Function} options.commit
	 * @return {void}
	 */
	removeAllRecentCalls({ commit }) {
		commit(REMOVE_ALL_RECENT_CALLS)
	},

	/**
	 * Remove the current item from the filters.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {String} payload.filter
	 * @return {void}
	 */
	removeFilter({ commit, state }, filter) {
		if (filter && state.filters.statuses.indexOf(filter) > -1) {
			commit(REMOVE_STATUS_FILTERS, filter)
		}
	},

	/**
	 * Remove a call type filter.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {Object} callType
	 * @return {void}
	 */
	removeCallTypeFilter({ commit, state }, callType) {
		if (!callType) {
			return
		}

		const index = state.filters.types.findIndex(
			type => type.id === callType.id
		)

		if (index > -1) {
			commit(REMOVE_CALL_TYPE_FILTER, callType)
		}
	},

	/**
	 * Remove the current date range filter.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @return {void}
	 */
	removeDateRangeFilter({ commit, state }) {
		if (state.filters.starts && state.filters.ends) {
			commit(REMOVE_DATE_RANGE_FILTER)
		}
	},

	/**
	 * Remove the given recent call id from the store.
	 *
	 * @param {Function} options.commit
	 * @param {Number} id
	 * @return {void}
	 */
	removeRecentCall({ commit }, id) {
		commit(REMOVE_RECENT_CALL, id)
	},

	/**
	 * Remove the current search term.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.getters
	 * @param {Object} options.state
	 * @return {void}
	 */
	removeSearchFilter({ commit, getters, state }) {
		if (state.filters.search.length > 0) {
			commit(REMOVE_SEARCH_FILTER)
		}

		if (!getters.hasFilter) {
			commit(RESET_FILTERS)
		}
	},

	/**
	 * Set the filters to scope any future queries by.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Array} payload.search
	 * @param {Array} payload.statuses
	 * @param {Array} payload.types
	 * @param {Boolean} payload.urgent
	 * @param {Boolean} payload.nonUrgent
	 * @return {void}
	 */
	async setFilters(
		{ commit, dispatch, state },
		{
			starts,
			ends,
			providers,
			search,
			statuses,
			types,
			urgent = null,
			nonUrgent = null,
		}
	) {
		if (starts && starts !== state.filters.starts) {
			commit(SET_STARTS_TIME_FILTER, starts)
		}

		if (ends && ends !== state.filters.ends) {
			commit(SET_ENDS_TIME_FILTER, ends)
		}

		if (search && search !== state.filters.search) {
			commit(SET_SEARCH_FILTER, search)
		}

		if (statuses && statuses !== state.filters.items) {
			commit(SET_STATUS_FILTERS, statuses)
		}

		if (types && types !== state.filters.types) {
			commit(SET_CALL_TYPE_FILTERS, types)
		}

		if (providers && providers !== state.filters.providers) {
			commit(SET_CALL_PROVIDER_FILTERS, providers)
		}

		if (urgent !== null) {
			await dispatch('setUrgentFilter', urgent)
		}

		if (nonUrgent !== null) {
			await dispatch('setNonUrgentFilter', nonUrgent)
		}
	},

	/**
	 * Set the non-urgent call filter.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {Boolean} nonUrgent
	 * @return {void}
	 */
	setNonUrgentFilter({ commit, state }, nonUrgent = true) {
		if (state.filters.nonUrgent !== nonUrgent) {
			commit(SET_NON_URGENT_CALL_FILTER, nonUrgent)
		}
	},

	/**
	 * Set the urgent call filter.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {Boolean} urgent
	 * @return {void}
	 */
	setUrgentFilter({ commit, state }, urgent = true) {
		if (state.filters.urgent !== urgent) {
			commit(SET_URGENT_CALL_FILTER, urgent)
		}
	},

	/**
	 * Update the given call types.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {String} sid
	 * @param {Array} types
	 * @return {Promise}
	 */
	async update({ dispatch, rootGetters }, { sid, types }) {
		const response = await api()
			.partners(rootGetters.partner.id)
			.calls()
			.update(sid, types)

		dispatch('add', response.get('data', {}))

		return response
	},

	/**
	 * Update the given call's information.
	 *
	 * @param {Object} context
	 * @param {Object} payload
	 * @param {Number} payload.sid
	 * @param {Object} payload.callPatientDetails
	 * @param {Number?} payload.partner
	 * @return {Promise}
	 */
	async updateEMR(
		{ rootGetters },
		{ sid, callPatientDetails, partner = null }
	) {
		try {
			partner = partner || rootGetters.partner.id
			const response = await api()
				.partners(partner)
				.calls()
				.updateEMR(sid, callPatientDetails)

			return response
		} catch (error) {
			console.error('Error updating EMR:', error)
			throw error
		}
	},

	/**
	 * Update a specific field for the given call id.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.getters
	 * @param {Number} payload.id
	 * @param {String} payload.message
	 * @return {Promise}
	 */
	async updateCallAttribute({ commit, getters }, { id, key, value }) {
		const call = getters.find(id)

		value = value === DEFAULT_VAL && call[key] === null ? null : value

		if (!call || call[key] === value) {
			return
		}

		if (
			(key === 'callback_number' || key === 'pharmacy_phone_number') &&
			call[key] === `+1${value}`
		) {
			return
		}

		const response = await api()
			.calls()
			.patch(call.sid, key, value)

		commit(UPDATE_CALL_ATTRIBUTE, { id, key, value })

		return response
	},

	/**
	 * Update the given call note.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Object} note
	 * @return {void}
	 */
	async updateNote({ commit, dispatch, state }, note) {
		if (state.ids.includes(note.call_id)) {
			commit(UPDATE_CALL_NOTE, note)

			await dispatch('addNoteUpdatedEvent', note)
		}
	},

	setLoading({ commit }, value) {
		commit('SET_LOADING', value)
	},

	setIncompleteTotalCountOnly({ commit }, total) {
		commit(SET_INCOMPLETE_TOTAL_COUNT_ONLY, total)
	},
}

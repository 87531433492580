/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Determine if any call exist in the module.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	any(state) {
		return state.ids.length > 0
	},

	/**
	 * Get the current calls.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Array}
	 */
	calls(state, getters) {
		const ids =
			getters.hasFilter || getters.hasProviderFilter
				? state.filters.ids
				: state.ids

		return ids.map(id => state.items[id]).filter(Boolean)
	},

	/**
	 * Get the total number of calls in the module.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Number}
	 */
	count(state, getters) {
		if (getters.hasFilter || getters.hasProviderFilter) {
			return state.filters.ids.length
		}

		return state.ids.length
	},

	/**
	 * Find a call by the given id.
	 *
	 * @param {Object} state
	 * @return {Function}
	 */
	find(state) {
		return id => {
			return state.ids.includes(id) ? state.items[id] : null
		}
	},

	/**
	 * Find a call with the given SID.
	 *
	 * @param {Object} state
	 * @return {Function}
	 */
	findBySid(state, getters, rootState, rootGetters) {
		return sid => {
			return rootGetters.calls.find(call => call.sid === sid)
		}
	},

	/**
	 * Determine if any filters exist in the store.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	hasFilter(state) {
		if (state.filters.starts) {
			return true
		}

		if (state.filters.ends) {
			return true
		}

		if (state.filters.statuses.length > 0) {
			return true
		}

		if (state.filters.types.length > 0) {
			return true
		}

		if (state.filters.urgent || state.filters.nonUrgent) {
			return true
		}

		if (state.filters.search.length > 0) {
			return true
		}

		if (state.filters.providers && state.filters.providers.length > 0) {
			return true
		}

		return false
	},

	/**
	 * Determine if the provider filter exist in the store.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	hasProviderFilter(state) {
		return state.filters.providers.length > 0
	},

	/**
	 * Determine if recent calls exists in the store.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	hasRecentCalls(state) {
		return state.recentCalls.length > 0
	},

	/**
	 * Determine if the given call is a recent call.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	isRecentCall(state) {
		return id => {
			return state.recentCalls.includes(id)
		}
	},

	/**
	 * Get the meta object in the module.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Object}
	 */
	meta(state, getters) {
		if (!getters.hasFilter && !getters.hasProviderFilter) {
			return state.meta
		}

		return state.filters.meta
	},

	/**
	 * Get the total number of recent calls in the module.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	recentCallCount(state) {
		return state.recentCalls.length
	},

	isLoading(state) {
		return state.loading
	},

	startDateFilter(state) {
		return state.filters.starts
	},
	endDateFilter(state) {
		return state.filters.ends
	},
}

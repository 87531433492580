import Message from './Message'

/**
 * Alert success message.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class SuccessMessage extends Message {
	/**
	 * Create a new SuccessMessage instance.
	 *
	 * @param {String} message
	 */
	constructor(message) {
		super('success', 'Success:', message)
	}
}
